import React, { useEffect, useState } from 'react'
import '@geoapify/geocoder-autocomplete/styles/minimal.css'
import "./style.css"
import logo from "./img/beebot2.png"
import { Auth } from './components/auth.js';
import { Form } from './components/form.js';
import { Loader } from './components/loader.js';
import { Map } from './components/map.js';
import { onAuthStateChanged } from "firebase/auth";
import { addDoc, getDocs, getDoc, setDoc, collection, query, where, doc } from "firebase/firestore";
import DataTable from 'react-data-table-component';
import { auth, db } from "./firebase-config"
import { columns, getAutocomplete } from "./utils"
import { AccountSelect } from './components/accountSelect.js';
import { Calculator } from './components/calculator.js';
import { GPTmon } from './components/gpt.js';

function App() {

    const [output, setOutput] = useState("")
    const [distance, setDistance] = useState("")
    const [email, setEmail] = useState("")
    const [render, setRender] = useState(false)
    const [verified, setVerified] = useState(false)
    const [isPowerUser, setisPowerUser] = useState(false)
    const [isLoggingIn, setisLoggingIn] = useState(true)
    const [tableData, setTableData] = useState([])
    const [isError, setIsError] = useState(false)
    const [isLowConfidence, setIsLowConfidence] = useState(false)

    const [calcModifiers, setCalcModifiers] = useState({
        eqMarkup: "", confidence: "", targetBuyRate: "", distance: "", sameDayFlag: false
    })

    const [isAccountList, setIsAccountList] = useState(false)
    const [accountList, setAccountList] = useState([])
    const [selectedAccount, setSelectedAccount] = useState({})
    const [selectedAcctTitle, setSelectedAcctTitle] = useState('Default') 

    const [routeSwitch, setRouteSwitch] = useState({})
    const [priorRoute, setPriorRoute] = useState({})
    const [startLoader, setStartLoader] = useState(false)
    const [toggleFormFormat, setToggleFormFormat] = useState("Search")


    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                
                setRender(true)
                setisLoggingIn(false)
                
                if(auth.currentUser.emailVerified){
                    setVerified(true)
                }
                // ...
            } else {
                // User is signed out
                setRender(false)
                setisLoggingIn(false)

            }   
            
            console.log('render status:', render)
            console.log('verified status:', verified)
        })

    }, [])
    
    useEffect( () => {

        const getAccountList = async () => {
            try{
                console.log("trying to get account lists counter")
                const currentUserEmail = auth.currentUser.email                
                const accountCollectionRef = collection(db, 'accounts')
                const q = query(accountCollectionRef, where('rep', '==', currentUserEmail))
                const data = await getDocs(q)
                console.log("what the account query returns", data)
                const filteredData = data.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id
                }))
                console.log('filtered data is', filteredData)
                if(filteredData.length > 0) { setIsAccountList(true) }

                const defRef = doc(db, "accounts", "Default");
                const defSnap = await getDoc(defRef);
                const defaultData = {...defSnap.data(), id: defSnap.id}

                filteredData.unshift(defaultData)

                console.log("filtered data after adding the Default option", filteredData)

                setAccountList(filteredData)
                
            } catch(e) {
                console.error(e)
            }
        }

        

        const checkIsPowerUser = async () => {
            try {
                const currentUserEmail = auth.currentUser.email                
                const accountCollectionRef = collection(db, 'powerUsers')
                const q = query(accountCollectionRef, where('email', '==', currentUserEmail))
                const data = await getDocs(q)
                const filteredData = data.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id
                }))
                console.log('power user list data is', filteredData)
                if(filteredData.length > 0) { setisPowerUser(true) }

            } catch(e) {
                console.error(e)
            }
        }

        const getSearchLogs = async () => {
            try {
                const currentUserEmail = auth.currentUser.email                
                const accountCollectionRef = collection(db, 'logs')
                const q = query(accountCollectionRef, where('email', '==', currentUserEmail))
                const data = await getDocs(q)
                const filteredData = data.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id
                }))
                console.log('log list data is', filteredData)
                if(filteredData.length > 0) { 
                    filteredData.map((log) => {
                        setTableData(prevTableData => [ {
                            id: log.id,
                            eqType: log.eqType,
                            shipDate: log.shipDate,
                            pickupCity: log.pickupCity,
                            pickupState: log.pickupState,
                            dropoffCity: log.dropoffCity,
                            dropoffState: log.dropoffState,
                            rate: log.rate,
                        }, ...prevTableData])
                    })

                
                }

                

            } catch(e) {
                console.error(e)
            }
        }

        if(verified) {
            getAccountList()
            checkIsPowerUser()
            getSearchLogs()
        }
    }, [verified])

    useEffect(() => {
        var firstItem = accountList.find(x=>x!==undefined);
        setSelectedAccount(prev => ({
            ...prev,
            ...firstItem
            }));
    }, [accountList])

    const setAccountMarkups = async () => {
        await setDoc(doc(db, "accounts", "Default"),
        {eqMarkup: {
            flat: {mult: 1.085, add: 0},
            van: {mult: 1.1, add: 0},
            reefer: {mult: 1.1, add: 0},
            hotshot: {mult: .7595, add: 0},
            }
        },
        {merge: true}
        )
    }

    const handleRouteSwitch = () => {
        setRouteSwitch(true)
    }

    const handleAccountSelect = (event) => {
        setSelectedAcctTitle(event.target.value)

        var changeToAccount = accountList.find(acct=>{return acct.title===event.target.value});
        setSelectedAccount(prev => ({
            ...prev,
            ...changeToAccount
            }));

        console.log('acct list is', accountList)
        console.log('acct list index 0 title is', accountList[0].title)
    }


    const handleInputForm = async (rate, fields, pickedRate, eqMarkup, lowConfidenceFlag, isSameDay, coords, isPickupAutoCoord, isDropoffAutoCoord) => {
        console.log('this is table data', tableData)
        console.log('these are the coords', coords)
        
        console.log('this is the rate ', rate)
        console.log('selected account is', selectedAccount)     
        console.log('selected account title is', selectedAcctTitle)
        console.log('isPickupAutoCoord is', isPickupAutoCoord)
        console.log('isDropoffAutoCoord is', isDropoffAutoCoord)

        setIsError(false)
        setIsLowConfidence(lowConfidenceFlag)

        if(rate == 'NaN') {
            setIsError(true)
            setOutput('Error')
            setEmail('Error processing lane. Check that Pickup and Dropoff Cities are valid cities and the autocomplete does not contain "City of" or "Township." If they do delete those words and run again. If this still fails click the plus icon and provide a Zip code which will always return a result')

        } else {

            if(coords != routeSwitch) {
                setStartLoader(true)
            }
           
            setOutput(`$${rate}`)
            setDistance(`${(pickedRate.distance).toFixed(0)} miles`)

            setGptInput(`Write an email replying to a ${fields.eqType} freight trucking bid from ${fields.pickupCity}, ${fields.pickupState} to ${fields.dropoffCity}, ${fields.dropoffState} for $${rate} on ${fields.shipDate}`)

            setCalcModifiers({ eqMarkup: eqMarkup, confidence: pickedRate.confidenceLevel, targetBuyRate: pickedRate.targetBuyRate, distance: pickedRate.distance, sameDayFlag: isSameDay });

            console.log('these are the email fields ', fields)
            setEmail(`Our current rate for ${fields.pickupCity}, ${fields.pickupState} to ${fields.dropoffCity}, ${fields.dropoffState} is $${rate} all-in/truckload.\nThank you!`)
            
            const docRef = await addDoc(collection(db, "logs"), {
                email: auth.currentUser.email,
                eqType: fields.eqType,
                shipDate: fields.shipDate,
                pickupCity: fields.pickupCity,
                pickupState: fields.pickupState,
                dropoffCity: fields.dropoffCity,
                dropoffState: fields.dropoffState,
                rate: rate,
              });

            console.log('this is the doc ref', docRef)

            setTableData(prevTableData => [ {
                id: docRef.id,
                eqType: fields.eqType,
                shipDate: fields.shipDate,
                pickupCity: fields.pickupCity,
                pickupState: fields.pickupState,
                dropoffCity: fields.dropoffCity,
                dropoffState: fields.dropoffState,
                rate: rate,
            }, ...prevTableData]);

            console.log(tableData)

            
            setRouteSwitch(coords)
            
            // if(isPickupAutoCoord && isDropoffAutoCoord){
            //     console.log("I am using the autopicker coords")
            //     setRouteSwitch(coords)
            // } else {
            //     console.log('i am calculating new coordinates for map', fields.pickupCountry)
            //     getAutocomplete(`${fields.pickupCity}, ${fields.pickupState}, ${fields.pickupCountry === "CA" ? "Canada" : fields.pickupCountry}`).then(
            //         pLoc => {
            //             console.log("pLoc is", pLoc)
            //             getAutocomplete(`${fields.dropoffCity}, ${fields.dropoffState}, ${fields.dropoffCountry === "CA" ? "Canada" : fields.dropoffCountry}`).then(
            //                 dLoc => {
            //                     console.log("dLoc is", dLoc)
            //                     let tempCoords = {pLat: pLoc.results[0].lat, pLon: pLoc.results[0].lon, dLat: dLoc.results[0].lat, dLon: dLoc.results[0].lon }

            //                     // tempCoords.pLat = pLoc.results[0].lat
            //                     // tempCoords.pLon = pLoc.results[0].lon
            //                     // tempCoords.dLat = dLoc.results[0].lat
            //                     // tempCoords.dLon = dLoc.results[0].lon

            //                     console.log("coords right before setting Route Switch", tempCoords)

            //                     setRouteSwitch(tempCoords)
            //                 }
            //             )
            //         }
            //     )
            // }
            
            setStartLoader(false)
        }

        
    }

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    const requestOptionsV = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: 'duaIgEIpL0cu5pmYicgEMuZJ7z63'
      };

    const handleVerify = () => {
        fetch("https://verify-ojolp2uv4q-uc.a.run.app", requestOptionsV)
    }

    const [gptInput, setGptInput] = useState("")
    const [gptQuery, setGptQuery] = useState("")
    const [gptToggle, setGptToggle] = useState(false)

    const handleGptInput = (e) => {
        setGptInput(e.target.value)
    }

    const initiateGPT = () => {
        setGptQuery(gptInput)
        setGptToggle(!gptToggle)
    }


    if(isLoggingIn) {
        return (
            <div>
                <Loader isLoading={true} />
            </div>
        )
    } else if (!verified && render) {
        return(
        <div className='verify-container'>
            <p className='verify-text'>Please verify account via email and then refresh this page</p>
        </div>
        )
        
    }
    else if(render && verified) {
        return (
            <div>     
                <header>
                    <div className="container custom-row-container-width">
                        <div className="row">
                            <div className="col-sm-4">
                                <img src={logo} alt="logo" className="logo"/>
                            
                                <h1 className="header-text">Bee-Bot</h1>
                            </div>    
                            <div className='col-sm-6'>
                                <div className='row'>

                                    <AccountSelect onSelectChange={handleAccountSelect} isAccountList={isAccountList} selectedAcctTitle={selectedAcctTitle} accountList={accountList}/>
                                     
                                    <div className='col-sm-5'>
                                        {/* <button onClick={setAccountMarkups}>Write</button> 
                                        <p>{!isEmpty(selectedAccount) ? selectedAccount.eqMarkup.flat.mult : ''}</p>    */}
                                        {/* <button onClick={handleVerify}>Verify</button> */}
                                    </div>                               
                                </div>                               
                            </div>                                
                            <div className="col-sm-2">
                                <Auth renderCondition={render} />
                            </div>
                        </div>
                    </div>
                </header> 
                <section className="container custom-row-container-width">    
                    <div className="row content-row">
                        <div className="col-lg-6 col-custom-left"> 
                            <div className='content-container'>
                                <h5>Bid Finder</h5>
                                <Form onSubmitForm={handleInputForm} selectedAcctTitle={selectedAcctTitle} selectedAccount={selectedAccount} toggleFormFormat={toggleFormFormat}/> 
                            </div>                              
                            <div className='content-container margin-top-spacer'>
                                <h5>Results</h5>
                                <form> 
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className="calc-label">Recommended Bid</label>
                                            <output type="text" className={isError ? 'form-control error-border': 'form-control'} id="output" placeholder="Sell Rate">{output}</output>
                                            
                                        </div>
                                        <div className="col-md-4 sell-rate-wrapper">
                                            <label className="calc-label">Distance</label>
                                            <output type="text" className='form-control' id="output" placeholder="Distance">{distance}</output>
                                        </div>
                                        <div className="col-md-4 float-right">
                                            <label className="alert-rate" id="alert">{isLowConfidence ? '⚠️ Low Confidence ⚠️' : ''}</label>
                                        </div>
                                    </div>                                      
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{isError ? 'Error Message' : 'Email Template'}</span>
                                        </div>
                                        <textarea className={isError ? 'form-control email-display error-border': 'form-control email-display'}  aria-label="Email Template" id="email" value={email}></textarea>
                                    </div>                                                                                            
                                </form> 
                            </div>                                                                                                                                                              
                        </div>
                        <div className="col-lg-6 col-custom-left">
                            <Map  routeSwitch={routeSwitch} startLoader={startLoader}/>
                            {/* <GPTmon body={gptQuery} toggle={gptToggle} />
                            <input value={gptInput} onInput={handleGptInput}/>
                            <button className="button" onClick={initiateGPT} >Initiate Gpt</button > */}
                            <Calculator isPowerUser={isPowerUser} calcModifiers={calcModifiers} />
                        </div>                       
                    </div>
                </section>       
                <section className="container custom-row-container-width table-section">             
                    <div className="row content-row">
                        <div className="col-lg-12">
                            <div className="table-wrapper">
                            
                                <h5>Log History</h5>
                                
                                <DataTable columns={columns} data={tableData} pagination defaultSortFieldId={2} defaultSortAsc={false} dense={true} highlightOnHover={true} />
                            </div>     
                        </div>     
                    </div>              
                </section>
            </div>
        )            
    }  else {
        return(
            <div >
                <div className='container login-container'>

                    <div className='row text-center'>
                        <div className='col-sm-6 center'>
                            <img src={logo} alt="logo" className="logo"/>
                        </div>                    
                    </div>
                    <div className='row text-center'>
                        <div className='col-sm-6 center'>
                            <Auth />
                        </div>
                    </div>
                </div>
                
            </div>
        )
   
    }
}

export default App